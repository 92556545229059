import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate format`}</strong>{` -- reformat certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate format <crt-file> [--out=<file>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate format`}</strong>{` prints the certificate or CSR in a different format.`}</p>
    <p>{`Only 2 formats are currently supported; PEM and ASN.1 DER. This tool will convert
a certificate or CSR in one format to the other.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
Path to a certificate or CSR file.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--out`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Path to write the reformatted result.`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Convert PEM format to DER:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate format foo.pem
`}</code></pre>
    <p>{`Convert DER format to PEM:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate format foo.der
`}</code></pre>
    <p>{`Convert PEM format to DER and write to disk:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate format foo.pem --out foo.der
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      